import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import '../styles/WatchlistComponent.css'

const WatchlistComponent = ({ onAdvanceChange }) => {
  const [address, setAddress] = useState('');
  const [advance, setAdvance] = useState(false);
  const [recommendedAddresses, setRecommendedAddresses] = useState([]);
  const [watchlistAddresses, setWatchlistAddresses] = useState([]);
  const addressesFetched = useRef();
  const watchlistRef = useRef();
  const watchlistAddressesRef = useRef();
  const watchListLastSize = useRef();
  const inputRef = useRef();
  const hintRef = useRef();
  const [hint, setHint] = useState(null);
  const isWatchlistUpdating = useRef(false);

  useEffect(() => {
    // Create abort controllers for fetch requests
    const abortControllerAddresses = new AbortController();
    const abortControllerWatchlist = new AbortController();

    // Build the data
    buildData(abortControllerAddresses, abortControllerWatchlist);

    // Cleanup function to abort ongoing fetch requests
    return () => {
      abortControllerAddresses.abort();
      abortControllerWatchlist.abort();
    };
  }, []);

  useEffect(() => {
    if (watchlistAddresses.length > 0) {
      setAdvance(true);
      toggleExpand(true);
    } else {
      setAdvance(false);
      toggleExpand(false);
    }
  }, [watchlistAddresses])

  useEffect(() => {
    onAdvanceChange(advance);
  }, [advance, onAdvanceChange]);

  const buildData = async (abortControllerAddresses, abortControllerWatchlist) => {
    try {
      // Fetch addresses only if not already fetched
      if (!addressesFetched.current) {
        const addressesResponse = await fetch('/api/addresses', {
          signal: abortControllerAddresses.signal,
        });

        if (addressesResponse.status === 401) {
          console.log('status is 401')
          const data = await addressesResponse.json();
          if (data.redirectTo) {
            window.location.href = data.redirectTo; // Perform browser redirect
            return;
          }
        }

        if (addressesResponse.ok) {
          const addressesData = await addressesResponse.json();
          addressesFetched.current = addressesData.addresses; // Set once and don't change this copy
        } else {
          console.error('Failed to fetch addresses');
        }
      }

      // Always fetch the watchlist
      const watchlistResponse = await fetch('/api/watchlist', {
        signal: abortControllerWatchlist.signal,
      });
      if (watchlistResponse.ok) {
        setCurrentHeights();

        const watchlistData = await watchlistResponse.json();

        const formattedWatchlist = watchlistData.watchlist.map((address) => ({
          address: address.toLowerCase(),
          saved: true
        }));
        setWatchlistAddresses(formattedWatchlist);

        const uniqueAddresses = addressesFetched.current.filter(
          (addressObj) =>
            !watchlistData.watchlist.some(
              (item) => item.toLowerCase() === addressObj.address.toLowerCase()
            )
        ); // Filter out any recommended addresses already in the watch list

        const formattedAddresses = uniqueAddresses.map((addressObj) => ({
          address: addressObj.address.toLowerCase(),
          saved: false, // Assume 'saved' is false for recommended addresses
        }));

        setRecommendedAddresses(formattedAddresses);


      } else {
        console.error('Failed to fetch watchlist');
      }

    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Error fetching data:', error);
      }
    }
  };

  const setCurrentHeights = () => {
    if (watchlistAddressesRef.current) {
      const currentHeight = watchlistAddressesRef.current.offsetHeight; // Get the current height
      watchlistAddressesRef.current.style.setProperty('--current-height', `${currentHeight}px`);
    }
  }

  const addToWatchlist = (address) => {
    console.log('addToWatchlist: ' + address);
    setCurrentHeights();

    setWatchlistAddresses((prevAddresses) => {
      const lowerCaseAddress = address.toLowerCase();
      const isAddressInWatchlist = prevAddresses.some(
        (item) => item.address.toLowerCase() === lowerCaseAddress
      );

      if (!isAddressInWatchlist) {
        requestWatchlistUpdate(address, true);
        return [{ address: lowerCaseAddress, saved: false }, ...prevAddresses];
      }
      return prevAddresses;
    });

    setRecommendedAddresses((prevAddresses) =>
      prevAddresses.filter(
        (item) => item.address.toLowerCase() !== address.toLowerCase()
      )
    );

  };


  const removeFromWatchlist = (address) => {
    console.log('removeFromWatchlist: ' + address);
    setCurrentHeights();

    setWatchlistAddresses((prevAddresses) => {
      const lowerCaseAddress = address.toLowerCase();
      const isAddressInWatchlist = prevAddresses.some(
        (item) => item.address.toLowerCase() === lowerCaseAddress
      );

      if (isAddressInWatchlist) {
        requestWatchlistUpdate(address, false);
        return prevAddresses.filter(
          (item) => item.address.toLowerCase() !== lowerCaseAddress
        );
      }
      return prevAddresses;
    });

    const isOriginalAddress = addressesFetched.current.some(
      (item) => item.address.toLowerCase() === address.toLowerCase()
    );

    if (isOriginalAddress) {
      setRecommendedAddresses((prevAddresses) => {
        const lowerCaseAddress = address.toLowerCase();
        const isAddressInRecommended = prevAddresses.some(
          (item) => item.address.toLowerCase() === lowerCaseAddress
        );

        if (!isAddressInRecommended) {
          return [
            ...prevAddresses,
            { address: lowerCaseAddress }
          ];
        }
        return prevAddresses;
      });
    }
  };

  const requestWatchlistUpdate = async (address, keep) => {
    console.log('requestWatchlistUpdate: ' + address + ' ' + keep + ' updating: ' + isWatchlistUpdating.current);

    if (isWatchlistUpdating.current) {
      return;
    }

    const method = keep ? 'POST' : 'DELETE';

    try {
      console.log('Call API/watchlist');
      isWatchlistUpdating.current = true;

      const response = await fetch('/api/watchlist', {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ address }),
      });

      if (response.ok) {
        console.log('Email address updated successfully');
        if (keep) {
          // Find the address in watchListAddresses
          setWatchlistAddresses((prevAddresses) => {
            return prevAddresses.map((item) => {
              if (item.address.toLowerCase() === address.toLowerCase()) {
                // Set saved property to true
                return { ...item, saved: true };
              }
              return item;
            });
          });
        }
      } else {
        console.error('Failed to update email address');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      isWatchlistUpdating.current = false;
    }

  }

  function toggleExpand(expand) {

    if (watchlistAddresses.length == 0) {
      watchlistRef.current.classList.remove('expand');
      watchListLastSize.current = watchlistAddresses.length;
      return;
    }

    // Toggle the expand class to trigger the animation
    if (expand) {
      if (watchListLastSize.current != watchlistAddresses.length) { // Only forces a reflow for resetting the animation if size has changed
        watchlistRef.current.classList.remove('expand');
        void watchlistRef.current.offsetWidth;
        watchlistRef.current.classList.add('expand');

      } else if (!watchlistRef.current.classList.contains('expand')) {
        watchlistRef.current.classList.add('expand');
      }

      // Get the full content height (used for max height)
      const contentHeight = watchlistAddressesRef.current.scrollHeight; // No need to add 'px' here
      watchlistAddressesRef.current.style.setProperty('--dynamic-height', `${contentHeight}px`);

    } else {
      // Remove the expand class to collapse
      watchlistRef.current.classList.remove('expand');

      // Set max-height to 0 when collapsing and reset other properties
      watchlistAddressesRef.current.style.setProperty('--dynamic-height', '0px');
      watchlistAddressesRef.current.style.setProperty('--current-height', '0px');
    }

    watchListLastSize.current = watchlistAddresses.length;
  }



  const handleSubmit = async (e) => {
    e.preventDefault();

    const allowedRegex = /[^a-zA-Z0-9.!#$%&'*+/=@?^_`{|}~-]/;

    let hintMessage;

    if (address.length === 0) {
      hintMessage = "Please type in an address or domain";
    } else if (!address.includes(".")) {
      hintMessage = "Address is missing a domain name (e.g., .com, .org, .me)";
    } else if (allowedRegex.test(address)) {
      hintMessage = "Address contains invalid characters";
    } else {
      hintMessage = null;
    }

    if (hintMessage) {

      setHint(hintMessage);
      hintRef.current.classList.add('show');
      inputRef.current.classList.add('incomplete');

      setTimeout(() => {
        hintRef.current.classList.remove('show');
        inputRef.current.classList.remove('incomplete');
      }, 2500);

      return;
    }

    if (address) {
      const addressLC = address.toLowerCase();

      addToWatchlist(addressLC);

      handleClear();
    }
  };

  const handleAdd = (event) => {
    console.log('handleAdd')
    addToWatchlist(event.currentTarget.dataset.address);
  };

  const handleDelete = (event) => {
    removeFromWatchlist(event.currentTarget.dataset.address);
  }

  const handleClear = () => {
    setAddress('');
  };

  return (
    <div className="watchlist" ref={watchlistRef}>
      <div ref={hintRef} className='hint'>&nbsp;{hint}&nbsp;</div>
      <form onSubmit={handleSubmit} >
        <input ref={inputRef} className="address-input" type="text" inputMode="email" autoComplete="email" placeholder="Type in email address or domain" value={address} onChange={(e) => setAddress(e.target.value)} />
        {address && (
          <button type="button" className="clear" onClick={handleClear}>
            <FontAwesomeIcon className="icon" icon={faCircleXmark} />
          </button>
        )}
        <button className="right-button" type="submit">Add</button>
      </form>
      <div className="watchlist-addresses" ref={watchlistAddressesRef}>
        {Array.isArray(watchlistAddresses) && watchlistAddresses.length > 0 && (
          <div className="address-list">
            {watchlistAddresses.map((item, index) => (
              <div className={`address ${item.saved ? 'saved' : ''}`} id={item.address} key={index} >
                <FontAwesomeIcon className="icon" icon={faCheck} />
                <div className='entry'>{item.address}</div>
                <button className="delete" onClick={handleDelete} data-address={item.address} >
                  <FontAwesomeIcon className="icon" icon={faTrashCan} />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
      {Array.isArray(recommendedAddresses) && recommendedAddresses.length > 0 && (
        <div className='recommended-addresses'>
          <div className="note"><FontAwesomeIcon className="icon" icon={faCircleCheck} />Suggested for you</div>
          <div className='address-list'>
            {recommendedAddresses.map((item, index) => (
              <button className="address" key={index} data-address={item.address} onClick={handleAdd} >
                <FontAwesomeIcon className="icon" icon={faPlus} />
                <div className='entry'>{item.address}</div>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default WatchlistComponent;
