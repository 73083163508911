import React from 'react';

const Join = () => {
  return (
    <div className='join onboarding'>
      <div className="onboarding-title"></div>
      <div className="onboarding-main">
        <h3>Effortlessly know what's <br />next for your kids</h3>
        <h3>Stress-free school and sports <br />scheduling for you</h3>
      </div>
      <div className="onboarding-continue">
        <button className='google-button continue' onClick={() => { window.location.href = '/api/auth/google' }}>Sign In</button>
        <div className='note'>By continuing, you agree to our <a href="#">Privacy Policy</a> and <a href="#">Terms of Use</a>.</div>
      </div>
    </div>
  );
};

export default Join;