import React, { useState, useEffect } from 'react';

const JoinCalendar = () => {

  return (
    <div className='calendar onboarding'>
      <div className="onboarding-title"><h2>Simple Schedule needs<br/> Calendar Access</h2></div>
      <div className="onboarding-main">
        <h3>Connect to save important events <br/>to your Google Calendar</h3>
      </div>
      <div className="onboarding-continue">
        <button className='gcal-button continue' onClick={() => { window.location.href = '/api/auth/google/gcal' }}>Allow Access</button>
        <button onClick={() => window.history.back()} className="cancel">Go Back</button>
      </div>
    </div>
  );
};

export default JoinCalendar;
