import './App.css';
import { BrowserRouter as Layout, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Join from './pages/Join';
import JoinEmail from './pages/JoinEmail';
import JoinCalendar from './pages/JoinCalendar';
import JoinWatchlist from './pages/JoinWatchlist';
import JoinSuccess from './pages/JoinSuccess';
import Focus from './pages/Focus';
import Profile from './pages/Profile';
import MessageComponent from './components/MessageComponent';
import FeedContextLayout from './components/FeedContextLayout';
import EventComponent from './components/EventComponent';
import { GlobalPromptsProvider } from './components/GlobalPromptsContext'; // Import the context provider
//import posthog from 'posthog-js'

const App = () => {

  /*
  posthog.init('phc_l2r4ZVdWQRu16y66kW0YXTF5SEcejLaq4H1DUZxVWJW',
      {
          api_host: 'https://us.i.posthog.com',
          person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
      }
  )
  */

  return (
    <GlobalPromptsProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/join" element={<Join />} />
          <Route path="/join/email" element={<JoinEmail />} />
          <Route path="/join/watchlist" element={<JoinWatchlist />} />
          <Route path="/join/calendar" element={<JoinCalendar />} />
          <Route element={<FeedContextLayout />} >
            <Route path="/join/success" element={<JoinSuccess />} />
            <Route path="/focus" element={<Focus />} >
              <Route path="/focus/event/:id" element={<EventComponent />} />
              <Route path="/focus/message/:id" element={<MessageComponent />} />
            </Route>
          </Route>
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </Layout>
    </GlobalPromptsProvider>
  );
};

export default App;