import moment from 'moment';

const now = Date.now();

const getDemoMap = () => {
    const mentions = [{ _id: "demo-mention-" + now, sourceSender: "Johannes Tacskovics", sourceDate: now, sourceMessageId: "demo-message-" + now }];
    const demoEvent = {};
    demoEvent._id = "demo-event-" + now;
    demoEvent.demoEvent = true;
    demoEvent.dateTime = true;
    demoEvent.start = moment(now).toISOString();
    demoEvent.startLocal = moment(now).local().format("YYYY-MM-DDTHH:mm:ss");
    demoEvent.eventName = "Welcome to Simple Schedule"
    demoEvent.description = "Effortlessly know what's next for your kids. Stress-free schedules created from the important info in your email messages."
    demoEvent.firstView = false;
    demoEvent.mentions = mentions;

    const events = [demoEvent];
    const demoMap = new Map(events.map(event => [event._id, event]));

    return demoMap;
}

const getDemoMessage = (username) => {
    const firstName = username.split(' ')[0]
    return {
        creationDate: now,
        senderInfo: { address: "johannes@simpleschedule.ai", sender: "Johannes T." },
        subject: "[Demo]: Welcome to Simple Schedule",
        html: "<html><body>"
            + "<p>Hi "+firstName+","
            + "<br/>Thanks for joining today! I want to make this app better for you.</p>"
            + "<p>Simple Schedule creates your kids' schedule using your email.</p>"
            + "<p>Just like this example, if there's a message that mentions a deadline, a game, homework, or the week ahead, this schedule will capture it.</p>"
            + "<p>I have many ideas on how to make this better - please share feedback on how this can be better for you.</p>"
            + "<p>Thanks for joining!<br/>Johannes</p>"
            + "</body></html>"
    }
}

export { getDemoMap, getDemoMessage }