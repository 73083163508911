import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import '../styles/ToastComponent.css';

const ToastComponent = ({ title, description, icon, displayTime = 5000, onClose }) => {
    const [isFadingOut, setIsFadingOut] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsFadingOut(true); // Start fade-out animation
            setTimeout(onClose, 200); // Delay removal until animation completes
        }, displayTime);

        return () => clearTimeout(timeout); // Clean up timeout
    }, [displayTime, onClose]);

    return (
        <div className={`toast-container ${isFadingOut ? 'fade-out' : ''}`}>
            {icon && <div className="toast-icon">{icon}</div>}
            <div className="toast-content">
                <h3 className="toast-title">{title}</h3>
                <p className="toast-description">{description}</p>
            </div>
            <button className="toast-close" onClick={() => setIsFadingOut(true)}>
                <FontAwesomeIcon className="icon" icon={faXmark} />
            </button>
        </div>
    );
};

export default ToastComponent;
