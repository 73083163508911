import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import FocusFeed from '../components/FocusFeed';
import MessageComponent from '../components/MessageComponent';
import EventComponent from '../components/EventComponent';
import CreateComponent from '../components/CreateComponent';
import { FeedContext } from '../components/FeedContext';


const Focus = () => {
  const { demoMode } = useContext(FeedContext);
  const [eventId, setEventId] = useState(null);
  const [messageId, setMessageId] = useState(null);
  const navigate = useNavigate();

  const openEvent = (id) => {
    setEventId(id);
    navigate(`/focus/event/${id}`);
  };

  const closeEvent = () => {
    setEventId(null);
    navigate(`/focus`);
  };

  const openMessage = (id) => {
    setMessageId(id);
    navigate(`/focus/message/${id}`);
  };

  const closeMessage = () => {
    setMessageId(null);
    navigate(`/focus`);
  };

  return (
    <div className="focus">
      {eventId && <EventComponent eventId={eventId} closeEvent={closeEvent} openMessage={openMessage} />}
      {messageId && <MessageComponent messageId={messageId} closeMessage={closeMessage} openEvent={openEvent} />}
      <FocusFeed openEvent={openEvent} closeEvent={closeEvent} openMessage={openMessage} closeMessage={closeMessage} />
      {!demoMode && <CreateComponent />}
    </div>
  );
};

export default Focus;
