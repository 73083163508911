import React, { useEffect, useLayoutEffect, useState, useRef, useContext } from 'react';
import moment from 'moment'
import { FeedContext } from './FeedContext';
import { useGlobalPrompts } from './GlobalPromptsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faCalendarWeek, faCalendarDay, faClock, faBoltLightning, faEye, faEyeSlash, faCalendarXmark, faQuestion, faFileCircleXmark, faSquareArrowUpRight, faXmark, faFilePdf, faArrowCircleLeft, faCircleInfo, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { getFirstTwoInitials, getColors, getDomain, truncateText } from '../utils/FormatUtils';
import DisclosureComponent from './DisclosureComponent';
import EventIconLabel from '../components/EventIconLabel';
import '../styles/EventComponent.css'
import { faCircleXmark, faEnvelope } from '@fortawesome/free-regular-svg-icons';

const EventComponent = ({ eventId, closeEvent, openMessage }) => {
    const { events, updateEvent, demoMode, userProfile } = useContext(FeedContext);
    const { showPrompt, showToast } = useGlobalPrompts();
    const [event, setEvent] = useState(null);
    const overlayRef = useRef(null);
    const drawerRef = useRef(null);
    const shadowRef = useRef(null);
    const baseRef = useRef(null);
    const descriptionRef = useRef(null);
    const isDraggingRef = useRef(false);
    const startYRef = useRef(0);
    const currentYRef = useRef(0);
    const [like, setLike] = useState(null);
    const [dislike, setDislike] = useState(null);
    const [firstView, setFirstView] = useState(null);
    const [isClampAdjusted, setIsClampAdjusted] = useState(false);
    const [disclosureOpen, setDisclosureOpen] = useState(false);
    const [hideLabel, setHideLabel] = useState(null);
    const [hideIcon, setHideIcon] = useState(null);

    useEffect(() => {
        if (events && eventId) {
            const fetchedEvent = events.get(eventId);
            if (fetchedEvent) {
                setEvent(fetchedEvent);
                setLike(fetchedEvent.like);
                setDislike(fetchedEvent.dislike);
            }
        }
    }, [events, eventId]);


    // Function to open hyperlink in a new window
    const handleOpenLink = (hyperlink) => {
        if (hyperlink) {
            window.open(hyperlink, "_blank", "noopener,noreferrer");
        }
    };

    const handleDislikeClick = (dislikeReason) => {
        const toggle = !dislike; // Toggle the dislike value

        if (!toggle) {
            setHideLabel(labelHide);
            setHideIcon(faEyeSlash);
        } else {
            setHideLabel(labelUnhide);
            setHideIcon(faEye);
        }

        if (demoMode) {
            boltCoachmark.current.classList.add('hide');
            setDislike(toggle);
            updateEvent({ ...event, dislike: toggle });
            return;
        }

        fetch('/api/events/' + eventId + '/dislike', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ toggle, dislikeReason })
        })
            .then(response => {
                if (response.ok) {
                    setDislike(toggle);
                    updateEvent({ ...event, dislike: toggle });
                } else {
                    // Handle errors here
                    console.error('Failed to update dislike');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });

        setDisclosureOpen(false);
    }

    const toggleDisclosure = (openState) => {
        if (!demoMode) {
            setDisclosureOpen(openState)
        }
    }

    const handleLikeClick = () => {
        const toggle = !like;

        if (demoMode) {
            boltCoachmark.current.classList.remove('hide');
            setLike(toggle);
            updateEvent({ ...event, like: toggle });
            return;
        }

        if (userProfile && !userProfile.calendarProvider) {
            showPrompt({
                icon: <img src="/gcallogo.svg" />,
                title: 'Save to Google Calendar',
                description: <span>Connect to save events to your Google Calendar with one touch</span>,
                onConfirm: () => { window.location.href = '/join/calendar' },
                confirmMessage: 'Connect',
                cancelMessage: 'Try later',
            });

            return;
        }


        fetch('/api/events/' + eventId + '/like', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ toggle: toggle }),
        })
            .then(response => {
                if (response.ok) {
                    setLike(toggle);
                    updateEvent({ ...event, like: toggle });
                    const eventDetails = truncateText(event.eventName, 16) + ' on ' + moment(event.startLocal).format("MMM D")
                    if (toggle) {
                        showToast({
                            icon: <img src="/gcallogo.svg" />,
                            title: 'Added to Google Calendar',
                            description: eventDetails + ' was added'
                        });
                    } else {
                        showToast({
                            icon: <img src="/gcallogo.svg" />,
                            title: 'Removed from Google Calendar',
                            description: eventDetails + ' was removed'
                        });
                    }
                } else {
                    // Handle errors here
                    showToast({
                        icon: '❌',
                        title: 'Error',
                        description: "Oops, this event wasn't added to Google Calendar"
                    });
                    console.error('Failed to update likes');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const labelHide = 'Hide Event';
    const labelUnhide = "Unhide Event";
    const labelDateIncorrect = 'Wrong date or time'
    const labelDetailsIncorrect = 'Details are incorrect'
    const labelIrrelevant = 'Irrelevant info'

    const disclosureItems = [
        { label: hideLabel, icon: hideIcon, onClick: () => handleDislikeClick(null) },
        { label: labelDateIncorrect, icon: faCalendarXmark, onClick: () => handleDislikeClick(labelDateIncorrect) },
        { label: labelDetailsIncorrect, icon: faFileCircleXmark, onClick: () => handleDislikeClick(labelDetailsIncorrect) },
        { label: labelIrrelevant, icon: faQuestion, onClick: () => handleDislikeClick(labelIrrelevant) },
    ];

    const defaultOpacity = 0.5;

    useEffect(() => {
        if (event && overlayRef.current && shadowRef.current) {
            overlayRef.current.classList.add('event-loaded');
            shadowRef.current.style.opacity = defaultOpacity;
        }
    }, [event]);

    useEffect(() => {
        const drawer = drawerRef.current;
        // Ensure the drawer ref is not null
        if (!drawer) {
            return;
        }

        if (!dislike) {
            setHideLabel(labelHide);
            setHideIcon(faEyeSlash);
        } else {
            setHideLabel(labelUnhide);
            setHideIcon(faEye);
        }

        const handleTouchStart = (e) => {
            console.log('handleTouchStart')

            drawer.style.animation = 'none' // Clear animation when starting the drag
            drawer.style.transition = ''; // Remove the transition to avoid interference with other animations or drags
            startYRef.current = currentYRef.current = e.touches[0].clientY; // Use ref to store startY
            isDraggingRef.current = true;
        };

        const handleTouchMove = (e) => {
            console.log('handleTouchMove')

            if (isDraggingRef.current) {
                e.preventDefault();

                const diffY = e.touches[0].clientY - currentYRef.current;

                const drawerBottom = parseFloat(getComputedStyle(drawerRef.current).bottom);

                const setY = drawerBottom - diffY;

                const drawerHeight = parseFloat(getComputedStyle(drawerRef.current).height);
                const opacity = ((drawerHeight + drawerBottom) / drawerHeight) * defaultOpacity;

                if (setY <= 0) {
                    // Move the drawer exactly as much as the diffY value
                    drawer.style.bottom = `${setY}px`;

                    // Update currentYRef.current so that the next move is based on the new position
                    currentYRef.current = e.touches[0].clientY;

                    // Adjust tint of overlay
                    shadowRef.current.style.opacity = opacity;

                } else {
                    // Move drawer to top position at the end of movement
                    drawer.style.bottom = `0px`;

                    // Adjust tint of overlay
                    shadowRef.current.style.opacity = defaultOpacity;
                }
            }
        };


        const handleTouchEnd = () => {
            console.log('handleTouchEnd')

            isDraggingRef.current = false;

            const drawer = drawerRef.current;
            const drawerBottom = Math.abs(parseFloat(getComputedStyle(drawer).bottom));

            // Add a smooth transition for the bottom property
            drawer.style.transition = 'bottom 0.15s ease-in-out';

            // Check if the drawer has been dragged beyond the halfway point of its height
            if (drawerBottom > drawer.getBoundingClientRect().height / 2) {
                hideDrawer();
            } else {
                drawer.style.bottom = '0px'; // Reset position
                shadowRef.current.style.opacity = defaultOpacity;
            }
        };

        // Attach event listeners
        drawer.addEventListener('touchstart', handleTouchStart);
        drawer.addEventListener('touchmove', handleTouchMove);
        drawer.addEventListener('touchend', handleTouchEnd);

        return () => {

            if (drawer) {
                drawer.removeEventListener('touchstart', handleTouchStart);
                drawer.removeEventListener('touchmove', handleTouchMove);
                drawer.removeEventListener('touchend', handleTouchEnd);
            }

        };
    }, [eventId]);

    const hideDrawer = () => {
        console.log('hideDrawer');
        const drawer = drawerRef.current;

        // Clear any conflicting styles
        drawer.style.animation = 'none';
        drawer.style.transition = 'none'; // Temporarily remove transition

        // Force a reflow (this ensures the new transition takes effect)
        const height = drawer.offsetHeight;

        // Apply the transition and hide the drawer
        drawer.style.transition = 'bottom 0.15s ease-in-out';
        drawer.style.bottom = '-100%'; // Target position

        shadowRef.current.style.opacity = 0;

        setTimeout(() => {
            closeEvent(); // Close after transition is complete
        }, 150);
    }

    const postFirstView = () => {
        if (demoMode) {
            setFirstView(true);
            updateEvent({ ...event, firstView: true });
            return;
        }

        if (event && !event.firstView && !demoMode) { // Only set once
            fetch('/api/events/' + eventId + '/firstview', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ firstView: true }),
            })
                .then(response => {
                    if (response.ok) {
                        setFirstView(true);
                        updateEvent({ ...event, firstView: true });
                    } else {
                        // Handle errors here
                        console.error('Failed to update firstView');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    useLayoutEffect(() => {

        postFirstView(); // Logs first view of the event

        if (descriptionRef.current && baseRef.current && !isClampAdjusted) {
            // Get the bounding rectangles of both the parent (eventRef) and the child (descriptionRef)
            const parentRect = baseRef.current.getBoundingClientRect();
            const descriptionRect = descriptionRef.current.getBoundingClientRect();

            // Check if the description is clipped by comparing their bottom edges
            const isClipped = descriptionRect.bottom > parentRect.bottom;

            if (isClipped) {
                // Calculate how much of the description is clipped
                const clipAmount = Math.ceil(descriptionRect.bottom - parentRect.bottom);
                const descriptionHeight = Math.ceil(descriptionRef.current.getBoundingClientRect().height);
                const computedStyle = window.getComputedStyle(descriptionRef.current);
                const padding = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
                const margin = parseFloat(computedStyle.marginTop) + parseFloat(computedStyle.marginBottom);
                let lineHeight = parseFloat(computedStyle.lineHeight); //lineHeight set in CSS

                // Default to font size multiplier if line height is invalid
                //const fontSize = parseFloat(computedStyle.fontSize);
                //if (isNaN(lineHeight) || lineHeight === 0) {
                //    lineHeight = fontSize * 1.3;
                //}

                const numberOfLines = Math.round((descriptionHeight - margin - padding) / lineHeight);
                const linesClipped = Math.ceil(clipAmount / lineHeight);
                const newLineClamp = numberOfLines - linesClipped;

                // Apply the adjusted line clamp
                descriptionRef.current.style.webkitLineClamp = newLineClamp;
                setIsClampAdjusted(true);
            }
        }
    }, [event]);

    const renderHyperlink = () => {
        if (!event) {
            return null;
        }

        if (event.hyperlink) {
            return event.hyperlink;
        }

        // Loop through mentions
        for (let mention of event.mentions) {
            if (mention.sourcePDF) {
                return mention.sourceURL;  // Assuming 'sourceURL' is a property of mention
            }
        }

        return null;  // Return null if no hyperlink found
    };

    const hyperlink = renderHyperlink();

    const renderMentioners = (mention) => {
        const firstTwo = getFirstTwoInitials(mention.sourceSender);
        const colors = getColors(firstTwo);

        return (
            <div
                className="source"
                key={mention._id}
                onClick={(e) => {
                    e.preventDefault();
                    openMessage(mention.sourceMessageId);
                    hideDrawer();
                }}
                style={{
                    background: `linear-gradient(100deg, ${colors.primary} 0%, ${colors.tertiary} 70%, ${colors.quaternary} 100%)`,
                    border: `0.2rem solid ${colors.secondary}`
                }}
            >
                <div className="message-sender">{firstTwo}</div>
            </div>
        );
    };

    const renderMentionNote = (event) => {

        const mentionDates = [];
        // mentionDates.push(moment(event.sourceDate).format('MMM D'));

        let message = (event.mentions.length > 1 ? event.mentions.length + ' mentions' : '1 mention') + " on ";

        for (const mention of event.mentions) {
            mentionDates.push(moment(mention.sourceDate).format('MMM D'))
        }

        const uniqueMentionDates = [...new Set(mentionDates)];

        if (uniqueMentionDates.length == 1) {
            message += uniqueMentionDates[0];
        } else if (uniqueMentionDates.length == 2) {
            message += uniqueMentionDates.join(' and ')
        } else if (uniqueMentionDates.length > 2) {
            message += uniqueMentionDates.slice(0, -1).join(', ') + ', and ' + uniqueMentionDates[uniqueMentionDates.length - 1];
        }

        return message;
    }

    const boltCoachmark = useRef(null);
    const sourcesCoachmark = useRef(null);

    const renderedContent = (
        <div className='event-drawer-overlay' ref={overlayRef}>
            <div className="event-drawer" ref={drawerRef} >
                {event !== null && (
                    <div className="event-content">
                        <div className="close-handle" ></div>
                        <button className="close-btn" onClick={hideDrawer} ><FontAwesomeIcon className="icon" icon={faXmark} /></button>
                        <div className="header">
                            <EventIconLabel firstView={event.firstView} eventName={event.eventName} includeLabel={true} tentative={event.tentative} />
                        </div>
                        <div className="event" >
                            <div className="base-content" ref={baseRef}>
                                <DisclosureComponent
                                    isOpen={disclosureOpen}
                                    toggleDisclosure={toggleDisclosure}
                                    disclosureItems={disclosureItems}
                                />
                                <div className="title">
                                    {event.eventName}
                                </div>
                                {event.monthEvent ? (
                                    <div className="month">
                                        <FontAwesomeIcon className="icon" icon={faCalendarDays} />
                                        {`Month of ${moment(event.startLocal).startOf('month').format('MMMM')}`}
                                    </div>
                                ) : null}
                                {event.weekEvent ? (
                                    <div className="week">
                                        <FontAwesomeIcon className="icon" icon={faCalendarWeek} />
                                        {`${moment(event.startLocal).startOf('week').format('ddd, MMM D')} - ${moment(event.startLocal).endOf('week').format('ddd, MMM D')}`}
                                    </div>
                                ) : null}
                                {!event.monthEvent ? (
                                    <div className="day">
                                        <FontAwesomeIcon className="icon" icon={faCalendarDay} />
                                        {event.start ? moment(event.startLocal).format('dddd, MMMM D') : ''}
                                    </div>
                                ) : null}
                                {event.dateTime && event.startLocal ? (
                                    <div className="start">
                                        <FontAwesomeIcon className="icon" icon={faClock} />
                                        {moment(event.startLocal).format('h:mm a')}
                                    </div>
                                ) : null}
                                {event.dateTime && event.endLocal ? (
                                    <div className="end">
                                        {` - ${moment(event.endLocal).format('h:mm a')}`}
                                    </div>
                                ) : null}
                                <div className="description" ref={descriptionRef} >
                                    {event.description}
                                </div>
                            </div>
                            <div className="action">
                                {hyperlink ? (
                                    <button className="go" onClick={(e) => { handleOpenLink(hyperlink); }}>
                                        <span className="label">Go</span><FontAwesomeIcon className="icon" icon={faSquareArrowUpRight} />
                                        <div className="hint">{getDomain(hyperlink)}</div>
                                    </button>
                                ) : null}
                                <div className="right-action">
                                    <button onClick={(e) => { handleLikeClick() }} className={`like ${like ? 'true' : 'false'}`}>
                                        <FontAwesomeIcon icon={faBoltLightning} />
                                    </button>
                                </div>
                                {demoMode && (
                                    <div className={`bolt coachmark ${like ? '' : 'hide'}`} ref={boltCoachmark}>
                                        <FontAwesomeIcon className="icon" icon={faCircleInfo} />
                                        <div className="instruction">Connect Google Calendar <br />to&nbsp; save events externally</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="sources">
                            <div className="source-messages">
                                {event.mentions.length > 0 ? (
                                    event.mentions.map(mention => (
                                        renderMentioners(mention)
                                    ))
                                ) : (
                                    renderMentioners(event.mentions[0])
                                )}
                            </div>
                            <span className="note"><FontAwesomeIcon icon={faEnvelope} /> {renderMentionNote(event)}</span>
                            {demoMode && (
                                <div className="coachmark" ref={sourcesCoachmark}>
                                    <FontAwesomeIcon className="icon" icon={faArrowCircleLeft} />
                                    <div className="instruction">
                                        Tap to see the email for this event
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="shadow" ref={shadowRef} onClick={hideDrawer}></div>
        </div>
    );

    return renderedContent;
};

export default EventComponent;