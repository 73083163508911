import React, { useEffect, useRef } from 'react';
import '../styles/PromptComponent.css';

const PromptComponent = ({
    icon,
    title,
    description,
    confirmAction,
    confirmMessage = 'Okay',
    cancelMessage = 'Cancel',
    onClose,
}) => {
    const overlayRef = useRef(null);

    const handleClickOutside = (e) => {
        if (overlayRef.current && e.target === overlayRef.current) {
            onClose();
        }
    };

    const handleConfirm = () => {
        if (confirmAction) confirmAction();
        onClose();
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [onClose]);

    return (
        <div
            ref={overlayRef}
            className="prompt-dialog-overlay"
            onClick={handleClickOutside}
        >
            <div className="dialog-container">
                {icon && <div className="icon-container">{icon}</div>}
                <h2 id="dialog-title" className="dialog-title">{title}</h2>
                <p className="dialog-description">{description}</p>
                <div className="dialog-buttons">
                    <div className="dialog-cancel" onClick={onClose}>
                        {cancelMessage}
                    </div>
                    <button className="dialog-confirm" onClick={handleConfirm}>
                        {confirmMessage}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PromptComponent;
