import chroma from "chroma-js";


const getDomain = (url) => {
  try {
    // Use the URL constructor to parse the URL
    const parsedUrl = new URL(url);

    // Get the hostname (e.g., www.google.com or forms.google.com)
    const hostname = parsedUrl.hostname;

    // Split the hostname by '.' and filter out common subdomains
    const parts = hostname.split('.');
    if (parts.length > 2 && parts[0] === 'www') {
      parts.shift(); // Remove 'www'
    }

    // Return the second-to-last part of the domain (e.g., 'google' from google.com)
    return parts[parts.length - 2];
  } catch (error) {
    console.error('Invalid URL:', error);
    return null; // Return null for invalid URLs
  }
}

// Define the getInitials function
const getInitials = (name) => {
  // Split the name by "via" or "from" and take the first part
  const [firstPart] = name.split(/\s+via\s+|\s+from\s+/i);

  // Split the first part into words
  const words = firstPart.split(' ');

  let initials = '';

  words.forEach(word => {
    if (word.length > 0) {
      const initial = word.charAt(0).toUpperCase();
      initials += initial;
    }
  });

  if (initials.length > 5) {
    initials = initials.substring(0, 5)
  }

  return initials;
};

const getFirstTwoInitials = (name) => {
  const initials = getInitials(name);

  let firstTwo = initials;

  if (initials.length > 2) {
    firstTwo = initials.substring(0, 2)
  }

  return firstTwo;
}

const getColors = (abbreviation) => {
  const colors = {}
  colors.primary = generateHexColorFromChars(abbreviation);

  const analogousColors =  chroma.scale([colors.primary])
  .colors(1, 'hex') 
  .map(color => chroma(color)
  .set('hsl.h', '+30')
  .set('hsl.l', 0.8)
  .set('hsl.s', 0.2)); 
  colors.secondary = analogousColors[0];

  const gradientColors =  chroma.scale([colors.primary])
  .colors(2, 'hex') 
  .map(color => chroma(color)
  .set('hsl.h', '+60')
  .set('hsl.l', 0.95)
  .set('hsl.s', 0.9));

  colors.tertiary = gradientColors[0];
  colors.quaternary = gradientColors[1];

  return colors;
}

function letterToNumber(letter) {
  // Convert the letter to lowercase
  letter = letter.toLowerCase();

  // Convert the letter to its ASCII code
  const asciiCode = letter.charCodeAt(0);
  
  // Calculate the number based on its position (a=0, b=1, ..., z=25)
  const number = asciiCode - 97; // Subtract ASCII code for 'a'

  // Ensure the number is within the range of 0-25
  return Math.max(1, Math.min(26, number));
}

function generateHexColorFromChars(characters) {
  const char1 = letterToNumber(characters.substring(0,1)); // number 1-26
  const char2 = letterToNumber(characters.substring(1,2)); // number 1-26

  const colorIndex = (char1 + char2) // number 2-52
  const hueIndex = 0 + ((colorIndex - 2) * (360 - 0)) / (52 - 2);

  // Create a new color with adjusted saturation and lightness
  const hslColor = chroma(hueIndex, 0.95, 0.92, 'hsl');

  // Convert back to hex
  return hslColor.hex();
}

function truncateText(text, maxLength) {
  if (typeof text !== 'string') {
      throw new TypeError('Input must be a string.');
  }
  if (maxLength < 3) {
      throw new Error('maxLength must be at least 3 to include ellipsis.');
  }

  return text.length > maxLength
      ? text.slice(0, maxLength - 3) + '...'
      : text;
}

// Export the getInitials function
export { getInitials, getFirstTwoInitials, getColors, getDomain, truncateText };  