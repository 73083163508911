import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCircleXmark, faMagicWandSparkles, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import '../styles/CreateComponent.css';

const CreateComponent = ({ }) => {
    const containerRef = useRef();
    const overlayRef = useRef();
    const formRef = useRef();
    const [url, setUrl] = useState('');
    const [fetching, setFetching] = useState(false);

    const clickCreate = () => {
        containerRef.current.classList.toggle('expand')
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('handleSubmit');
        setFetching(true);
    }

    const handleClear = () => {
        console.log('handleClear')
        setUrl('');
    }

    const clearOverlay = (e) => {
        // Only clear if the exact element clicked is the overlay (not a child element)
        if (e.target === overlayRef.current) {
            containerRef.current.classList.remove('expand');
            handleClear();
        }
    };

    useEffect(() => {
        if (fetching) {
            setTimeout(() => {
                setUrl('');
                setFetching(false);
            }, 5000);
        }
    }, [fetching]);

    return (
        <div className="create-container" ref={containerRef} >
            <div className='overlay' ref={overlayRef} onClick={clearOverlay}>
                <div className={`create-form${fetching ? ' fetching': ''}`} ref={formRef}>
                    <form onSubmit={handleSubmit}>
                        <input className="url-input" type="url" inputMode="url" autoComplete="url" placeholder="Enter URL Here" value={url} onChange={(e) => setUrl(e.target.value)} />
                        {url && (
                            <button type="button" className="clear" onClick={handleClear}>
                                <FontAwesomeIcon className="icon" icon={faCircleXmark} />
                            </button>
                        )}
                        <button className="right-button" type="submit"><FontAwesomeIcon className="icon" icon={faWandMagicSparkles} /></button>
                    </form>
                    <div className='note'>Import events from a webpage or PDF</div>
                </div>
            </div>

            <div className="create-controls">
                <button className='create' onClick={clickCreate}>
                    <FontAwesomeIcon className="icon" icon={faPlus} />
                </button>
            </div>
        </div>
    );
};

export default CreateComponent;